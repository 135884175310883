<template>
  <div class v-loading="loadingItems">
    <div class="top-status mb-4">
      <ul class="statistic-info-wrap list" v-loading="loadingItems">
        <li class="list__item" v-for="(item, index) in statisticInfo" v-if="arr.static_info.show" :key="index" @click="navigateToPage(item)">
          <span class="list__item-icon" :style="{
            background: item.color,
          }">
            <span v-html="item.icon"></span>
          </span>
          <div style="display: flex; flex-direction: column; width: 100%">
            <div class="list__item-total">
              <span>
                {{ item.total | formatMoney }}
              </span>
            </div>
            <span class="list__item-subtitle">{{ item.subtitle }}</span>
          </div>
        </li>
      </ul>
      <div class="col-12">
        <div class="row">
          <el-dropdown trigger="click" class="ml-auto mb-1">
            <el-button size="mini" icon="el-icon-setting">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="custom-menu">
              <el-dropdown-item v-for="(column, index) in arr" :key="index">
                <el-checkbox :checked="column.show" @change="check(index, $event)">{{ $t('message.' + column.title) }}</el-checkbox>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="row">
          <top-collectors v-if="arr.bonus_doctors.show === true" class="" :doctor="doctor" :title="$t('message.doctor_bonuses')" />
        </div>
      </div>
    </div>

    <div class="charts-wrapper mb-4">
      <Hospitals v-if="arr.hospital.show" :filter="true" :title="$t(`message.hospital`)" :getColors="['#20c997', '#0ea5e9', '#f59746', '#ffc107']"
        :hospital="$t(`message.hospital`)" :day-hospital="$t(`message.daily_hospital`)" :data="hospital" />
      <Candidates v-if="arr.gender.show === true" :title="$t(`message.gender_patients`)" :first-child="$t(`message.male`)"
        :second-child="$t(`message.female`)" :data="gender" />
      <leads-by-source v-if="arr.age.show === true" :age="age" />
      <nft-statistic v-if="arr.orders.show === true" :data="order_chart" />
    </div>

    <div class="charts-wrapper mb-4">
      <MedicsReport v-if="arr.report_doctors.show === true" :title="$t(`message.report_doctors`)" :reports="reportDoctor"/>
<!--      <DrReport  v-if="arr.partner_bonus.show === true" :doctor="partner_doctor" :title="$t('message.partner_bonus')" />-->
      <nft-statistic-patients v-if="arr.patients.show === true" :title="$t(`message.patients`)" :data="patient_chart" />
      <subscriptions-overview v-loading="loadingPayment" v-if="arr.payments.show === true" :title="$t('message.payment')" :chart="payment_chart" :spec="'payment'"/>
      <subscriptions-overview v-loading="loadingCost" v-if="arr.expence.show === true" :title="$t('message.expence')" :chart="cost_chart" :spec="'cost'"/>
    </div>

    <div class="leads-by-source">
      <top-partner-collectors  v-if="arr.partner_doctor.show === true" :doctor="partner_doctor" :title="$t('message.partner_bonus')" />
      <deal-status  v-if="arr.top_services.show === true" :top_services="top_services" />
      <ClinicReport  v-if="arr.report_clinics.show === true" :reports="reportClinic" :title="$t('message.report_clinics')" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import orderChart from "@/views/orders/components/crm-chart";
import orderCountChart from "@/views/orders/components/crm-count-chart";
import transactionChart from "@/views/transactions/components/crm-chart";
import costTransactionChart from "@/views/costTransaction/components/crm-chart";
import patientChart from "@/views/patient/components/crm-chart";
import moment from "moment";
import { i18n } from "@/utils/i18n";

import NftStatistic from "@/components/home/NftStatistic.vue";
import DrReport from "@/components/home/DrReport.vue";
import Candidates from "@/components/home/Candidates.vue";
import TopCollectors from "@/components/home/TopCollectors.vue";
import MedicsReport from "@/components/home/MedicsReport.vue";
import ClinicReport from "@/components/home/ClinicReport.vue";
import SubscriptionsOverview from "@/components/home/SubscriptionsOverview.vue";
import LeadsBySource from "@/components/home/LeadsBySource.vue";
import DealStatus from "@/components/home/DealStatus.vue";
export default {
  name: "Home",
  components: {
    "order-chart": orderChart,
    "transaction-chart": transactionChart,
    "cost-transaction-chart": costTransactionChart,
    "patient-chart": patientChart,
    "order-count-chart": orderCountChart,
    NftStatistic,
    Candidates,
    TopCollectors,
    SubscriptionsOverview,
    LeadsBySource,
    MedicsReport,
    ClinicReport,
    DealStatus,
    DrReport,
  },
  data() {
    return {
      cacheHospital: parseInt(process.env.VUE_APP_CACHE_HOSPITAL),
      hospitalSchedule: parseInt(process.env.VUE_APP_SCHEDULE),
      env_hospital: parseInt(process.env.VUE_APP_HOSPITAL),
      env_medical: parseInt(process.env.VUE_APP_MEDICINE_CABINET),
      loadingItems: false,
      redirectDoctor: false,
      form: {},
      loadingData: false,
      loadingPayment: false,
      loadingCost: false,
      timestamp: "",
      arr:{
        static_info: {title: 'static_info', show: true},
        bonus_doctors: {title: 'doctor_bonuses', show: true},
        hospital: {title: 'hospital', show: true},
        gender: {title: 'gender_patients', show: true},
        age: {title: 'patient_ages', show: true},
        orders: {title: 'orders', show: true},
        report_doctors: {title: 'report_doctors', show: true},
        partner_bonus: {title: 'partner_bonus', show: false},
        patients: {title: 'patients', show: true},
        payments: {title: 'payments', show: true},
        expence: {title: 'expence', show: true},
        partner_doctor: {title: 'partner_doctor', show: true},
        top_services: {title: 'top_services', show: true},
        report_clinics: {title: 'report_clinics', show: true},
      },

      statisticInfo: [],
    };
  },
  computed: {
    ...mapGetters({
      auth_role: "auth/role",
      dashboardInf: "dashboards/dashboardInf",
      schedulePatients: "dashboards/schedulePatients",
      stasionaryPatients: "dashboards/stasionaryPatients",
      paymentsSum: "dashboards/paymentsSum",
      expences: "dashboards/expences",
      contractsCount: "dashboards/contractsCount",
      items: "dashboards/items",
      formTemplates: "dashboards/formTemplates",
      patientScheduleCount: "dashboards/patientScheduleCount",
      stasionaryPayments: "dashboards/stasionaryPayments",
      warehouseCount: "dashboards/warehouseCount",
      number_of_orders_today: "dashboards/number_of_orders_today",
      doctor: "dashboards/doctor",
      payment_chart: "payment/chart",
      cost_chart: "costs/chart",
      partner_doctor: "dashboards/partner_doctor",
      top_services: "dashboards/top_services",
      gender: "dashboards/gender",
      hospital: "dashboards/hospital",
      order_chart: "dashboards/order_chart",
      patient_chart: "dashboards/patient_chart",
      age: "dashboards/age",
      reportClinic: "dashboards/reportClinic",
      reportDoctor: "dashboards/reportDoctor",
    }),
  },
  created() {
    this.loadingItems = true;
    this.loadingPayment = true;
    this.loadingCost = true;
    this.dashboardInfo()
      .then(() => {
        this.pushItems();
      })
      .finally(() => {
        this.loadingItems = false;
      });
    this.payment();
    this.fetchData();
    if (this.auth_role.slug === "doctor" && this.redirectDoctor == false) {
      this.$router.push("doctor-cabinet/patients");
      this.redirectDoctor == true;
    }
    this.paymentChart().finally(() => {
      this.loadingPayment = false;
    });
    this.costChart().finally(() => {
      this.loadingCost = false;
    });
  },
  // mounted() {
  //   $(".app-main")
  //     .last()
  //     .addClass("topClass");
  // },
  methods: {
    ...mapActions({
      info: "homes/info",
      payment: "paymentTypes/index",
      dashboardInfo: "dashboards/dashboardInfo",
      paymentChart: "payment/chart",
      costChart: "costs/chart",
    }),
    changeCurrentTab(selectedNav) {
      this.$store.commit("CHANGE_CURRENT_TAB", selectedNav);
    },
    fetchData() {
      let date = new Date();
      this.timestamp = moment(date).format("YYYY-MM-DD");

      if (!this.loadingData) {
        this.loadingData = true;
        this.info()
          .then((res) => {
            this.form = res;
            this.loadingData = false;
          })
          .catch((err) => {
            console.log(err);
            this.loadingData = false;
          });
      }
    },
    navigateToPage (path) {
      this.$router.push(path);
    },
    pushItems() {
      this.statisticInfo = [
        {
          icon: '<i class="fa-solid fa-user-doctor"></i>',
          path: 'patients/index',
          total: this.items.patient_today_count,
          subtitle: i18n.t("message.number_of_patients_today"),
          percent: 0,
          color: "#fd7e14",
        },
        {
          icon: '<i class="fa-solid fa-user-doctor"></i>',
          path: 'patients/index',
          total: this.items.patient_week_count,
          subtitle: i18n.t("message.number_of_patients_week"),
          percent: 0,
          color: "#fd7e14",
        },
        {
          icon: '<i class="fa-solid fa-user-doctor"></i>',
          path: 'patients/index',
          total: this.items.patient_month_count,
          subtitle: i18n.t("message.number_of_patients_month"),
          percent: 0,
          color: "#fd7e14",
        },
        {
          icon: '<i class="fa-solid fa-user-doctor"></i>',
          path: 'patients/index',
          total: this.items.patient_count,
          subtitle: i18n.t("message.number_of_patients_total"),
          percent: 0,
          color: "#fd7e14",
        },
        {
          icon: '<i class="fa-solid fa-bed"></i>',
          path: 'patientHistory/index',
          total: this.items.day_hospital_for_today,
          subtitle: i18n.t("message.day_hospital_for_today"),
          percent: 0,
          color: "#0ea5e9",
        },
        {
          icon: '<i class="fa-solid fa-bed"></i>',
          path: 'patientHistory/index',
          total: this.items.day_hospital_for_week,
          subtitle: i18n.t("message.day_hospital_for_week"),
          percent: 0,
          color: "#0ea5e9",
        },
        {
          icon: '<i class="fa-solid fa-bed"></i>',
          path: 'patientHistory/index',
          total: this.items.day_hospital_for_month,
          subtitle: i18n.t("message.day_hospital_for_month"),
          percent: 0,
          color: "#0ea5e9",
        },

        {
          icon: '<i class="fa-regular fa-user"></i>',
          path: 'orders/index',
          total: this.items.order_total_count,
          subtitle: i18n.t("message.number_of_orders_total"),
          percent: 0,
          color: "#6366f1",
        },
        {
          icon: '<i class="fa-solid fa-hospital-user"></i>',
          path: 'patientHistory/index',
          total: this.items.hospital_patients_today,
          subtitle: i18n.t("message.hospital_patients_today"),
          percent: 0,
          color: "#20c997",
        },
        {
          icon: '<i class="fa-solid fa-hospital-user"></i>',
          path: 'patientHistory/index',
          total: this.items.hospital_patients_week,
          subtitle: i18n.t("message.hospital_patients_week"),
          percent: 0,
          color: "#20c997",
        },
        {
          icon: '<i class="fa-solid fa-hand-holding-heart"></i>',
          path: 'patientHistory/index',
          total: this.items.hospital_patients_month,
          subtitle: i18n.t("message.hospital_patients_month"),
          percent: 0,
          color: "#ffc107",
        },
        {
          icon: '<i class="fa-solid fa-hand-holding-heart"></i>',
          path: 'orders/index',
          total: this.items.order_month_count,
          subtitle: i18n.t("message.number_of_orders_month"),
          percent: 0,
          color: "#ffc107",
        },
        {
          icon: '<i class="fa-solid fa-file-pen"></i>',
          path: 'doctor-cabinet/AllPatients',
          total: this.items.order_written_count,
          subtitle: i18n.t("message.written_conclusions"),
          percent: 0,
          color: "#17a2b8",
        },
        {
          icon: '<i class="fa-solid fa-file-pen"></i>',
          path: 'printCenter/printCenter',
          total: this.items.order_closed_count,
          subtitle: i18n.t("message.closed_conclusions"),
          percent: 0,
          color: "#17a2b8",
        },
        {
          icon: '<i class="fa-solid fa-pen-to-square"></i>',
          path: 'doctor-cabinet/AllPatients',
          total: this.items.order_month_written_count,
          subtitle: i18n.t("message.number_of_written_conclusions_month"),
          percent: 0,
          color: "#007bff",
        },
        {
          icon: '<i class="fa-solid fa-pen-to-square"></i>',
          path: 'printCenter/printCenter',
          total: this.items.order_month_closed_count,
          subtitle: i18n.t("message.number_of_closed_conclusions_month"),
          percent: 0,
          color: "#007bff",
        },
      ];
    },
    check(index, checked) {
      this.arr[index].show = checked;

      this.loadingItems = true;
      this.dashboardInfo()
          .then(() => {
            this.pushItems();
          })
          .finally(() => {
            this.loadingItems = false;
          });
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.top-status {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
}

.charts-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.statistic-info-wrap,
.leads-by-source {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

@media screen and (max-width: 1440px) {
  .leads-by-source {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media screen and(max-width: 576px) {
  .leads-by-source {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

.chart-card {
  box-shadow: 0px 2px 5px 2px rgba($color: #303030, $alpha: 0.05);

}

.list {
  padding: 0;
  margin: 0;
  list-style: none;

  // list__item
  &__item {
    padding: 12px;
    border-radius: 8px;
    display: flex;
    gap: 12px;
    align-items: center;
    background: #fff;
    box-shadow: 0px 2px 5px 2px rgba($color: #303030, $alpha: 0.05);

    // list__item-icon
    &-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 36px;
      height: 36px;
      border-radius: 10px;
      background: #3b82f6;
      color: #fff;
      font-size: 18px;
    }

    // list__item-total
    &-total {
      font-size: 18px;
      font-weight: 600;
      color: #333;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    // list__item-percent
    &-percent {
      font-size: 14px;
      font-weight: 500;
      color: #10b981;

      &.loss {
        color: #f43f5e;
      }
    }

    // list__item-subtitle
    &-subtitle {
      font-size: 14px;
      font-weight: 400;
      color: #bfbfd1;
      line-height: 10px;
    }
  }
}
</style>

<style lang="scss">
.top-collectors {
  margin-bottom: 1.5rem;
}

.el-progress__text {
  margin: 10px 0;
  opacity: 0.8;
}

.el-progress {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mychart {
  border-radius: 10px;
}

.chrttext {
  color: #1e75a8;
  font-size: 19px;
  margin-bottom: 15px;
  font-weight: 700;
}

.charminitext {
  font-size: 13px;
  margin-left: 5px !important;
  color: #a2a7a2;
  font-weight: 500;
}

.home_link-pages i {
  border-radius: 5px;
  height: 70px;
  width: 70px;
  height: 7vw;
  width: 7vw;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.68s ease-out;
  transition: all 0.68s ease-out;
}

.bacg1 i {
  color: #40c9c6;
}

.bacg1:hover i {
  color: #ffffff;
  background-color: #40c9c6;
}

.bacg2 i {
  color: #8e55c7;
}

.bacg2:hover i {
  color: #ffffff;
  background-color: #8e55c7;
}

.bacg3 i {
  color: #f4516c;
}

.bacg3:hover i {
  color: #ffffff;
  background-color: #f4516c;
}

.bacg4 i {
  color: #36a3f7;
}

.bacg4:hover i {
  color: #ffffff;
  background-color: #36a3f7;
}

.bacg1 .text_icon>div {
  font-size: 28px;
  color: #40c9c6;
}

.bacg2 .text_icon>div {
  font-size: 28px;
  color: #8e55c7;
}

.click_link:hover .itme_blocks_pages {
  text-decoration: none;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.click_link .itme_blocks_pages {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.itme_blocks_pages {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 10px;
  min-height: 160px;
}

.itme_blocks_pages_botton {
  .itme_blocks_pages_botton_summa {
    line-height: 2rem;
    font-weight: 700;
    color: #303030;
    font-size: 16px;
  }

  .itme_blocks_pages_botton_title {
    color: #718096;
    font-size: 16px;
  }
}

.itme_blocks_pages_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info_topright {
  width: 40px;
  height: 25px;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #91c714;
}

.iconstyle {
  width: 40px;
  height: 40px;
  transition: 0.7s;
  display: inline-block;
}

a:hover {
  text-decoration: none;
}
</style>
